<template>
  <v-dialog
      v-model="dialog"
      width="380"
      transition="fab-transition"
  >
    <v-card class="pl-0 pr-0">
      <v-card-title>
        <v-icon class="mr-3">fas fa-box-open</v-icon>
        <span class="description-selected">
                    {{ description }}
                </span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pb-0">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="form.quantity"
                            ref="quantity"
                            label="Cantidad"
                            type="number"
                            autofocus
                            outlined
                            :rules="[
                                    () => !!form.quantity || 'Debe ingresar la cantidad',
                                    () => (!!form.quantity && parseInt(form.quantity) > 0) || 'Cantidad debe ser mayor a cero',
                                ]"
                            hide-details="auto"
                            required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-container class="pt-0 pb-0">
          <v-row>
            <v-col cols="6">
              <v-btn dark color="red" block @click="handleCancel">
                Cancelar
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn dark color="green" block @click="handleOk">
                Aceptar
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'ModalCantidad',
  props: {obtenerCantidad: Function},
  data() {
    return {
      dialog: false,
      description: null,
      form: {
        quantity: null
      },
      product: null
    }
  },
  methods: {
    show(product) {
      this.clearData();
      console.log('mostrando producto', product)
      this.description = product.description;
      this.form.quantity = product.quantity
      this.dialog = !this.dialog;
      this.product = product
    },
    async clearData() {
      this.description = null;
      this.form.quantity = null;
      this.product = null
      await this.clearErrors();
    },
    handleCancel() {
      this.dialog = false;
    },
    handleOk() {
      if (!this.existErrors()) {
        if (this.product.stock>=this.form.quantity) {
          this.product.quantity = Number.parseFloat(this.form.quantity)
        }else{
          this.product.quantity = Number.parseFloat(this.product.stock)
        }
        this.obtenerCantidad({...this.product})
        this.dialog = false;
        this.clearData()
      }
    },


    existErrors() {
      let hasErrors = false;

      Object.keys(this.form).forEach(f => {
        if (this.$refs[f]) {
          if (!this.form[f]) hasErrors = true
          this.$refs[f].validate(true);
        }
      });

      return hasErrors;
    },
    clearErrors() {
      Object.keys(this.form).forEach(f => {
        if (this.$refs[f]) this.$refs[f].reset()
      });
    },
  }
}
</script>

<style scoped>

</style>