export const operacionesListas = {
    incrementarOrDecrementByKey(list, keySearch, valueSearch, keySet, type = 'increment', valor = 1) {
        let findData = list.find(x => x[keySearch] === valueSearch)
        let valorSet = 0
        let quantity = 0
        if (type == 'decrement') {
            if (valor !== 1) valorSet = 0 - valor
            else valorSet = -1
        } else {
            if (valor !== 1) valorSet = valor
            else valorSet = 1
        }

        if (valor !== 1)
            findData[keySet] = findData[keySet] + valorSet
        else
            findData[keySet] = findData[keySet] + valorSet

        const findIndex = list.findIndex(x => x[keySearch] === valueSearch)
        list[findIndex] = findData;
        return {list: [...list], quantity: findData[keySet], indexList: findIndex};
    },

    findIndexList(list, target, key) {
        return list.findIndex(x => x[key] === target[key])
    },
    findDataList(list, target, key) {
        return list.find(x => x[key] === target[key]) || undefined
    },


    deleteItemOfList(list, target, key) {
        const findIndex = this.findIndexList(list, target, key)
        console.log(findIndex)
        if (findIndex !== -1) {
            list.splice(findIndex, 1)
        }
        return list
    },
    setItemOfList(list,target,key){

        const findIndex = this.findIndexList(list, target, key)
        if (findIndex!=-1){
            list[findIndex]=target;
        }

        return list;
    },
    setValueItemOfList(list, target, keySearch, keySet, valueSet) {
        const findIndex = this.findIndexList(list, target, keySearch)
    let data = list[findIndex]
        if (findIndex !== -1) {
            // console.log('propiedad a actualizar :key',keySet)
            // console.log('propiedad a actualizar :valor',data[keySet])
            data[keySet] = valueSet
            list[findIndex] = data;
        }

        return list;
    }
}