<template>
  <v-container v-if="selected_till" fluid class="h-100 pt-0 pb-0">
    <v-row justify="center">
      <v-col cols="6" align-self="center">
        <v-img
            class="white--text align-center img-closed-till"
            height="268"
            width="410"
            src="img/till-close.png"
        >
        </v-img>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="6">
        <div style="text-align: center;">
                    <span class="body-1 font-weight-bold">
                        La caja se encuentra cerrada, ingrese un monto para abrirla!
                    </span>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="5">
        <v-card outlined>
          <v-card-text class="pb-0">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      :value="amount_open"
                      label="Monto de apertura"
                      outlined
                      prefix="S/"
                      hide-details="auto"
                      @input="setAmountOpen"
                      required>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-container class="pt-0 pb-0">
              <v-row justify="center">
                <v-col cols="6" class="pt-0">
                  <v-btn dark
                         color="green"
                         block
                         :disabled="processing"
                         @click="handleOpenTill">
                    Abrir Caja
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex';

export default {
  name: 'CajaCerrada',
  data() {
    return {
      processing: false,
      tillForm: {
        id: null,
        amount: null
      }
    }
  },
  computed: {
    ...mapState('authentication', [
      'till_user_id'
    ]),
    ...mapState('tills', [
      'tills',
      'selected_till',
      'last_closed',
      'amount_open'
    ])
  },
  methods: {
    ...mapActions('tills', [
      'storeOperation',
      'getLastClosed',
      'getAvailable',
      'select_till',
      'setAmountOpen'
    ]),
    clearData() {
      this.tillForm.id = null;
      this.tillForm.amount = null;
      this.clearErrors();
    },
    handleCancel() {
      this.clearData();
    },
    async handleOpenTill(){
      this.processing = true;
      if (!this.existErrors()) {
        let operation = {
          till_id: this.selected_till.id,
          type: 'apertura',
          description: 'Apertura de caja',
          amount: this.amount_open,
          amount_card: null
        }

        await this.storeOperation(operation);
        this.setAmountOpen(0);
      }
      this.processing = false;
    },
    existErrors(){
      let hasErrors = false;

      Object.keys(this.tillForm).forEach(f => {
        if (this.$refs[f]) {
          if (!this.tillForm[f]) hasErrors = true
          this.$refs[f].validate(true);
        }
      });

      return hasErrors;
    },
    clearErrors() {
      Object.keys(this.tillForm).forEach(f => {
        if (this.$refs[f]) this.$refs[f].reset()
      });
    },
    async getCurrentUserTill(){
      let filters = {
        till_id: this.till_user_id
      }
      await this.getAvailable(filters);
      if (this.tills.length > 0) {
        this.select_till(this.tills[0]);
      }
    },
    async load () {
      await this.getCurrentUserTill();
      if (this.selected_till) {
        await this.getLastClosed({
          till_id: this.selected_till.id
        });
      }
    },
  },
  created () {
    this.load();
  },
  mounted() {
    this.clearErrors();
  }
}
</script>

<style scoped>
.img-closed-till{
  margin-left: auto !important;
  margin-right: auto !important;
}
</style>