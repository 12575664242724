<template>
  <v-dialog
      v-model="opciones.show"
      persistent
      max-width="700px"
  >
    <v-card>
      <div class="navigation-head">
        <span>Precios y descuentos</span>
      </div>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-title class="pt-2 pb-2" v-if="opciones.product!=null">
                  <span class="subtitle-2 font-weight-bold">{{ opciones.product.description }}</span>
                </v-card-title>
              </v-card>
              <br/>
              <v-card>
                <v-card-title class="pt-1 pb-1">
                  <span class="subtitle-2 font-weight-bold">Lista de precios</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pt-1 pb-1">
                  <v-radio-group v-model="price_selected" dense>
                    <v-radio
                        v-for="price in listPrices"
                        :key="price.id"
                        :value="price.price"
                        :label="price.price | currency('S/')"
                    >
                    </v-radio>
                  </v-radio-group>
                </v-card-text>
              </v-card>
              <br/>
              <v-card>
                <v-card-title class="pt-1 pb-1">
                  <span class="subtitle-2 font-weight-bold">Descuento</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-container class="pt-0 pb-0">
                    <v-row>
                      <v-col cols="4">
                        <v-btn-toggle
                            v-model="discount_type"
                            mandatory
                            color="green darken-3"
                            class="mr-3">
                          <v-btn value="Monto">
                            S/
                          </v-btn>
                          <v-btn value="Porcentaje">
                            %
                          </v-btn>
                        </v-btn-toggle>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field :label="`${discount_type} a descontar`"
                                      class="field-discount"
                                      v-model="discount_amount"
                                      outlined
                                      hide-details="auto"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
              <br/>
              <v-card>
                <v-card-title class="pt-1 pb-1">
                  <span class="subtitle-2 font-weight-bold">Nota</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pt-3 pb-3">
                  <v-textarea v-model="note"
                              outlined
                              rows="3"
                              hide-details="auto">
                  </v-textarea>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="4"></v-col>
          <v-col cols="3" style="display: flex">
            <v-btn
                dark
                color="primary"
                @click="fnCerrarModal()"
            >
              Cancelar
            </v-btn>
            <v-btn
                dark color="red"

                @click="registrarPrecio"
            >
              Registrar
            </v-btn>
          </v-col>

        </v-row>


      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiProducts from '@/apis/Products'
import products from "../../../../store/modules/products";

export default {
  name: "ModalPrecios",
  props: ['opciones', 'cerrarModal', 'product', 'defaultValues'],
  data: () => ({
    dialog: false,
    listPrices: [],
    price_selected: null,
    discount_value: 0.0,
    discount_type: "Monto",
    discount_amount: 0.0,
    note: ''
  }),
  mounted() {
  },
  watch: {
    product() {
      this.setData()
      this.fnGetPrecios()
    },
  },
  methods: {
    fnCerrarModal() {
      this.cerrarModal('cancelar')
      this.price_selected = null
      this.discount_value = 0.0
      this.discount_type = 'Monto'
      this.discount_amount = 0.0
      this.note = ''
    },
    setData() {
      if (this.product) {
        this.price_selected = Number.parseFloat(this.product.price).toFixed(2)
        this.discount_value = this.product.discount_value
        this.discount_type = this.product.discount_type
        this.discount_amount = this.product.discount_amount
        this.note = this.product.note
      }

    },
    fnGetPrecios() {
      const product = this.product;
      if (product != null) {
        ApiProducts.get_prices(product)
            .then(result => {
              this.listPrices = result.data
            })
            .catch(error => {
              this.listPrices = []
            })
      }
    },
    limpiarDatos() {
      this.price_selected = null
      this.discount_value = 0.0
      this.discount_type = 'Monto'
      this.discount_amount = 0.0
      this.note = ''
    },
    registrarPrecio() {
      const formPrecio = {
        price_selected: this.price_selected,
        discount_value: this.discount_value,
        discount_type: this.discount_type,
        discount_amount: this.discount_amount,
        note: this.note,
        product: {...this.product}
      }
      this.cerrarModal('registrar', formPrecio)
      this.limpiarDatos()
    }
  }
}
</script>

<style scoped>

</style>