<template>
  <v-container fluid class="pb-0">
    <div v-if="selected_till && selected_till.status==='abierta'">
      <template>
        <v-row class="h-100">
          <v-col cols="8" class="pt-1 pb-0">
            <Catalogo
                :list-catalago-origin="listCatalagoOrigin"
                :loading="loading"
                :selected-product="fnSeleccionarProducto"
            />
          </v-col>
          <v-col cols="4" class="pt-1 pb-0">
            <Carrito :list-carrito="listProductSelected"
                     :handle-decrease-item="handleDecreaseItem"
                     :handle-increase-item="handleIncreaseItem"
                     :handle-delete-item="handleDeleteItem"
                     :handle-change-bonus="handleChangeBonus"
                     :handle-setear-precio="handleSetearProducto"
                     :fn-registro-success-principal="fnRegistroSuccess"
                     :handle-add-quantity="handleAddQuantity"
            />
          </v-col>
        </v-row>
      </template>
    </div>
    <div v-else>
      <caja-cerrada></caja-cerrada>
    </div>
    <v-snackbar
        v-model="snackBar.show_message"
        color="error"
        :timeout="3000"
        :bottom="'bottom'"
        :right="'right'"
    >
      {{ snackBar.message }}
      <v-btn
          dark
          text
          @click="snackBar.snackbar = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
    <!---INICIANDO COMPONENTE DE MODAL PRECIOS-->
    <ModalPrecios :opciones="modalPrecios"
                  :cerrar-modal="fnCerrarModalPrecios"
                  :product="modalPrecios.product"/>
    <!----FIN DE COMPONENTE MODAL PRECIOS---->

    <ModalCantidad ref="modalQuantity" :obtener-cantidad="fnObtenerCantidadProduct" />
  </v-container>
</template>

<script>

import {mapState, mapActions} from 'vuex';

import Catalogo from './components/Catalogo'
import Carrito from './components/Carrito'
import CajaCerrada from './components/CajaCerrada';
import InventoriesApi from "../../../apis/Inventories";
import {operacionesListas} from "../../../functions/operaciones-listas";
import ModalPrecios from "./components/ModalPrecios";
import ModalCantidad from "./components/ModalCantidad";

export default {
  name: 'Nueva-venta',
  components: {
    ModalCantidad,

    ModalPrecios,
    Catalogo,
    Carrito,
    CajaCerrada
  },
  data() {
    return {
      listCatalagoOrigin: [],
      productSelected: null,
      listProductSelected: [],
      loading: {text: 'Cargado información', status: false},
      snackBar: {show_message: false, snackbar: false, message: '', position: 'bottom-right'},
      itemsBreadcrumbs: [
        {
          text: 'Ventas',
          disabled: true,
          href: '',
        }
      ],
      modalPrecios: {
        show: false,
        product: null,
        tipo_descuento: '',
        descuento_amount: 0.0,
        nota: '',
        precio_seleccionado: null
      }
    }
  },
  computed: {
    ...mapState('tills', [
      'tills',
      'selected_till'
    ])
  },
  methods: {
    ...mapActions('tills', [
      'getAvailable',
      'select_till'
    ]),
    fnObtenerCantidadProduct(product){
      if (product.discount_type=='Monto'){
        product.subtotal = product.price * parseFloat(product.quantity)
        product.discount_amount = parseFloat(product.discount_amount)
        product.discount_value = parseFloat(product.discount_amount)
      }

      if (product.discount_type=='Porcentaje'){
        product.subtotal = product.price * parseFloat(product.quantity)
        product.discount_value =parseFloat((product.discount_amount/100)*product.subtotal)
        product.discount_amount = parseFloat(product.discount_amount)
      }
      this.listProductSelected = operacionesListas.setItemOfList([...this.listProductSelected],
          product,
          'product_id')
    },
    handleAddQuantity(product){
      this.$refs.modalQuantity.show(product)
    },
    handleDecreaseItem(product) {
      const setObject = Object.assign({}, product)
      const list = [...this.listProductSelected]
      this.listProductSelected = []
      const resultOperacion = this
          .fnIncrementarDecrementarQuantity(list, 'product_id', setObject.product_id, 'quantity', 'decrement')
      this.listProductSelected = resultOperacion.list
      if (resultOperacion.quantity === 0) {
        this.listProductSelected.splice(resultOperacion.indexList, 1)
      }
    },
    handleIncreaseItem(product) {
      const setObject = Object.assign({}, product)
      const list = [...this.listProductSelected]
      this.listProductSelected = []
      const findProduct = {...list.find(x => x.product_id === product.product_id)}
      // agregamos uno para verificar el stock
      findProduct.quantity += 1
      console.log(findProduct)
      if (this.fnValidarStock(findProduct)) {
        // console.log('TEST')
        // regresamos a su valor normal
        findProduct.quantity -= 1
        const auxList = this
            .fnIncrementarDecrementarQuantity([...list],
                'product_id',
                setObject.product_id,
                'quantity',
                'increment')
        this.listProductSelected = auxList.list

      }

    },
    fnIncrementarDecrementarQuantity(list, keySearch, valueSearch, keySet, type = 'increment', valor = 1){
      let findData = list.find(x => x[keySearch] === valueSearch)
      let valorSet = 0
      let quantity = 0
      //incrementamos o sisminuimos el valor
      if (type == 'decrement') {
        if (valor !== 1) valorSet = 0 - valor
        else valorSet = -1
      } else {
        if (valor !== 1) valorSet = valor
        else valorSet = 1
      }

      if (valor !== 1) {
        findData[keySet] = findData[keySet] + valorSet
      }else {
        findData[keySet] = findData[keySet] + valorSet
      }
      if (findData!==undefined){
        if (findData.discount_type=='Monto'){
          findData.subtotal = findData.price * parseFloat(findData.quantity)
          findData.discount_amount = parseFloat(findData.discount_amount)
          findData.discount_value = parseFloat(findData.discount_amount)
        }
        console.log(findData.discount_type)
        if (findData.discount_type=='Porcentaje'){
          console.log('PROCENTAJE CALCULADO',findData.price * parseFloat(findData.quantity))
          findData.subtotal = findData.price * parseFloat(findData.quantity)
          findData.discount_value =parseFloat((findData.discount_amount/100)*findData.subtotal)
          findData.discount_amount = parseFloat(findData.discount_amount)

        }
      }
      console.log('data de incremento',findData)
      const findIndex = list.findIndex(x => x[keySearch] === valueSearch)
      list[findIndex] = findData;
      return {list: [...list], quantity: findData[keySet], indexList: findIndex};
    },
    handleDeleteItem(product) {
      this.listProductSelected = operacionesListas.deleteItemOfList([...this.listProductSelected], product, 'product_id')
    },
    handleCloseMessageValidation() {
      this.run_set_status_operation(false);
    },
    handleChangeBonus(product) {
      const setProduct = {...product}
      const setListProducts = [...this.listProductSelected]
      this.listProductSelected = []
      const isBonus = !setProduct.is_bonus
      const updatedList = operacionesListas.setValueItemOfList(setListProducts,
          setProduct,
          'product_id',
          'is_bonus',
          isBonus
      )
      this.listProductSelected = updatedList
    },
    fnCerrarModalPrecios(type = 'cancelar', objectInformacion = null) {
      if (type === 'cancelar') {
        this.modalPrecios.show = false
      }

      if (type == 'registrar') {
        this.modalPrecios.show = false
        let product = {...objectInformacion.product}
        if (!isNaN(parseFloat(objectInformacion.price_selected))) {
          product.price = parseFloat(objectInformacion.price_selected)
        }
        product.discount_type = objectInformacion.discount_type
        if (objectInformacion.discount_type=='Monto'){
          product.subtotal = product.price * parseFloat(objectInformacion.product.quantity)
          product.discount_amount = parseFloat(objectInformacion.discount_amount)
          product.discount_value = parseFloat(objectInformacion.discount_amount)
        }
        if (objectInformacion.discount_type=='Porcentaje'){
          product.subtotal = product.price * parseFloat(objectInformacion.product.quantity)
          product.discount_value =parseFloat((objectInformacion.discount_amount/100)*product.subtotal)
          product.discount_amount = objectInformacion.discount_amount

        }


        product.note = objectInformacion.note
        this.listProductSelected = operacionesListas.setItemOfList([...this.listProductSelected],
            product,
            'product_id')
      }
    },
    fnRegistroSuccess() {
      console.log('limpiando data')
      this.productSelected = null
      this.listProductSelected = []
      this.modalPrecios = {
        show: false,
        product: null,
        tipo_descuento: '',
        descuento_amount: 0.0,
        nota: '',
        precio_seleccionado: null
      }
      this.fnGetCatalogo()
    },
    handleSetearProducto(product) {
      console.log(product)
      this.modalPrecios.show = true
      this.modalPrecios.product = product
    },
    fnValidarStock(product) {
      if (product.quantity <= product.stock) return true
      else {
        this.snackBar = {snackbar: true, show_message: true, message: 'Sin Stock', position: 'bottom-right'}
        return false
      }
    },
    fnSeleccionarProducto(product) {
      let setProduct = {};
      const findProduct = this.listProductSelected.find(x => x.product_id === product.product_id)
      if (findProduct) {
        setProduct = {...findProduct}
        setProduct.quantity = (findProduct.quantity) + 1
        setProduct.subtotal = parseFloat(findProduct.price * setProduct.quantity)
      } else {
        setProduct = {
          ...product,
          commission: 0,
          discount_amount: 0,
          discount_type: "Monto",
          discount_value: 0,
          type_item: "product",
          total_gratuitas: 30,
          total_rounded: 0,
          type_operation: "sale",
          quantity: 1,
          subtotal: parseFloat(product.price),
          is_bonus: false,
          worker: null,
          worker_id: null,
          note: ''

        }
      }
      console.log('producto agregado', setProduct)

      // console.log('result validacion',this.fnValidarStock(setProduct))
      if (this.fnValidarStock(setProduct) === true) {
        // console.log('activando stock')
        const findIndex = this.listProductSelected.findIndex(x => x.product_id === product.product_id)
        if (findIndex !== -1) {
          this.listProductSelected.splice(findIndex, 1, setProduct)
        } else {
          this.listProductSelected.push(setProduct)
        }
      }

    },
    async getCurrentUserTill() {
      let filters = {
        till_id: this.till_user_id
      }
      await this.getAvailable(filters);
      if (this.tills.length > 0) {
        this.select_till(this.tills[0]);
      }
    },
    async fnGetCatalogo() {
      this.loading = {status: true, text: 'Cargando productos ...'}
      await InventoriesApi.catalogueToSale()
          .then(response => {
            this.listCatalagoOrigin = response.data
            this.loading = {status: false, text: ''}
          })
          .catch(error => {
            this.loading = {status: false, text: 'Error al cargar los productos'}
            console.log(error)
          })
    }
  },
  created() {
    this.getCurrentUserTill();
    this.fnGetCatalogo()
    // this.getAccounts();
  },
  mounted() {
    // this.run_set_status_operation(false);
  }
}
</script>

<style scoped>

</style>