<template>
  <div style="height: 90% !important">
    <template>
      <v-row v-if="!selected_station">

        <v-col cols="12" class="pt-1 pr-3">
          <v-text-field
              v-model="search_product"
              label="Buscar por nombre, código de barras"
              autofocus
              hide-details
              prepend-inner-icon="mdi-magnify"
              solo
              @keydown="handleInputSearchProduct">
          </v-text-field>
        </v-col>

      </v-row>
      <v-row v-else>

        <v-col cols="12" class="pt-1 pr-3">
          <v-text-field
              v-model="search_product"
              label="Buscar por nombre, código de barras"
              autofocus
              hide-details
              prepend-inner-icon="mdi-magnify"
              solo
              @keyup="handleInputSearchProduct"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pt-0 pb-0">
          <v-card class="section-catalogue">
            <v-card-text class="pl-0 pr-0 pt-0 pb-0">
              <template>
                <v-list v-if="type_item == 'product'" two-line dense class="pt-0">
                  <v-list-item v-if="loading.status">
                    <v-list-item-content>
                      <v-list-item-title align="center">
                        <v-row
                            class="fill-height"
                            align-content="center"
                            justify="center"
                        >
                          <v-col
                              class="text-title text-center"
                              cols="12"
                          >
                            {{loading.text}}
                          </v-col>
                          <v-col cols="6">
                            <v-progress-circular
                                :size="50"
                                color="primary"
                                indeterminate
                            ></v-progress-circular>
                          </v-col>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-else v-for="product in listCatalogo" :key="product.id"
                               class="catalogue-item"
                               @click="handleSelectProduct(product)">
                    <v-list-item-content>
                      <v-list-item-title>
                        <span class="font-weight-bold">{{ product.description }}</span>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <v-icon color="red" class="mr-3" size="12">fas fa-tag</v-icon>
                        <span class="mr-3 font-italic font-weight-bold">Stock:</span>
                        <span class="font-weight-bold" v-if="product.stock">
                                                    {{ product.stock }}
                                                </span>
                        <span class="font-weight-bold" v-else>
                                                    0
                                                </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <span class="font-weight-bold">{{ product.price | currency('S/') }}</span>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </template>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>

  </div>
</template>

<script>

import {mapState, mapActions} from 'vuex';

import AddQuantity from '@/components/sales/AddQuantity';
import ArrayTools from '@/helpers/ArrayTools';
import {BarCodeSupport} from '@/plugins/BarCodeSupport';
import InventoriesApi from "../../../../apis/Inventories";
// import CreateService from '../services/CreateUpdate';

export default {
  name: 'CatalogoView',
  props:['listCatalagoOrigin','loading','selectedProduct'],
  components: {
    // AddQuantity,
    // CreateService
  },
  data() {
    return {
       search_product: null,
      type_item: 'product',
      listCatalogo:[]
    }
  },
  computed: {
    ...mapState('stations', [
      'selected_station'
    ])
  },
  methods: {
    customText(item) {
      let text = item.name;

      return text;
    },
    handleSelectProduct(product) {
      let setProduct={...product}
      this.selectedProduct(setProduct)
    },
    searchProductInCatalogue(searchValue) {
      this.run_set_catalogue([]);
      let filters = {
        category: this.category,
        description: this.search_product,
        unit_id: this.unit_id
      };
      this.getCatalogueToSale(filters);
    },
    handleInputSearchProduct(event) {
     const searchInput=event.target.value.trim()
      if (searchInput.length>0){
        this.listCatalogo=this.listCatalagoOrigin.filter(x=>x.description.toLowerCase().indexOf(searchInput.toLowerCase()) > -1)
      }else{
        this.listCatalogo=this.listCatalagoOrigin
      }

    },
  },
  created() {
    // this.fnGetCatalogo()
    // this.run_set_catalogue([]);
    // this.handleListHardware();

    // this.getServices();
  },
  mounted() {
    BarCodeSupport.$on('codeScanned', data => {
      this.selectBarcode(data)
    });
  },
  watch:{
    listCatalagoOrigin(){
      this.listCatalogo=this.listCatalagoOrigin
    }
  }
}
</script>

<style scoped>
.selected-option {
  color: #ffffff !important;
  background-color: #023145 !important;
}

.selected-option > .v-card__text > span {
  color: #ffffff !important;
  background-color: #023145 !important;
}

.section-catalogue {
  height: 83vh;
  overflow-y: auto;
}

.catalogue-item {
  text-transform: uppercase;
  border-bottom: 1px solid #e0e0e0;
  min-height: 50px !important;
}

.btn-product-category {
  display: inline-block !important;
  width: 100%;
}

.sale-option-label {
  font-size: .8rem !important;
  font-weight: 600;
}

.option-img {
  padding-left: 15%;
  padding-top: 12px;
}

.label-modality {
  padding-left: 15% !important;
}

.sale-option-img {
  background-color: #023145; /* #00908b;*/
  color: #ffffff;
  width: 83%;
  height: 40px;
  padding-top: 5px;
  border-radius: 4px;
  text-align: center;
}

.btn-modality {
  height: 80px !important;
  width: 114px !important;
  /*margin: auto;*/
}

.modality-select {
  background-color: #ffe330;
}

.frm-portabilidad {
  border: 1px solid;
}
</style>