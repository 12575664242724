<template>
  <v-menu
      v-model="menu2"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          v-model="date"
          label="Fecha"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
        locale="es"
        no-title
        v-model="date"
        @input="menu2 = false"
        @change="obtenerFecha($event)"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "DatepickerComponent",
  props:['obtenerFecha','defaultDate'],
  data(){
    return{
      date_invoice:'',
      menuDateInvoice:'',
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu2:false
    }
  },
  watch:{
    defaultDate:function (nuevaFecha,fechaAnterior) {
      console.log(nuevaFecha,fechaAnterior)
    }
  },
 mounted() {
    this.obtenerFecha(this.date)
 }
}
</script>

<style scoped>

</style>