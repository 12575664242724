<template>
  <div>
    <v-row class="pl-3 pr-3">
      <v-col cols="11" class="pb-4" v-if="customer == null">
        <v-autocomplete
            :value="customer_id"
            :items="listCustomers"
            label="Cliente"
            hide-details
            outlined
            :item-text="customText"
            item-value="id"
            clearable
            @change="handleSelectCustomer"
            :filter="customFilter"
        >
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title>{{ data.item.fullname }}</v-list-item-title>
              <v-list-item-subtitle v-if="data.item.type_document_id">
                {{ data.item.type_document.name }}: {{ data.item.document }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="1" class="pl-0" v-if="customer == null">
        <v-icon color="success" class="mt-2"
                @click="handleViewCreateCustomer">
          fas fa-plus-circle
        </v-icon>
      </v-col>
    </v-row>
    <v-row class="pl-3 pr-3" v-if="customer">
      <v-col cols="12" class="pt-0 pb-0">
        <v-list two-line class="pt-0 pb-0">
          <v-list-item class="pl-1 pr-1">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon color="green" class="mr-2" size="18">
                  fas fa-user-tag
                </v-icon>
                <span class="text-uppercase font-weight-bold cart-label-customer">{{ customer.fullname }}</span>
              </v-list-item-title>
              <v-list-item-subtitle>
                <span class="ml-8">{{ customer.type_document.name }}: {{ customer.document }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="handleClearCustomer" color="red">
                <v-icon>far fa-times-circle</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>

    <!---FORMULARIO NUEVO CLIENTE--->
    <NuevoCliente ref="formNuevoCliente" :getCliente="fnObtenerNuevoCliente"/>
    <!----FORMULARIO NUEVO CLIENTE--->
  </div>
</template>

<script>

import ApiCustomers from '@/apis/Customers'
import NuevoCliente from "./NuevoCliente";

export default {
  name: "ClienteSearchComponent",
  components: {NuevoCliente},
  props:{obtenerCliente:Function,limpiarData:Boolean},
  data() {
    return {
      listCustomersOrigin: [],
      listCustomers: [],
      customText: 'id',
      customer_id: null,
      customer:null
    }
  }
  , created() {
    this.fnGetCustomers()
  },
  watch:{
    fnLimpiarData(){
      if (this.limpiarData){
          this.fnSetCleanData()
      }
    }
  },
  methods: {
    fnObtenerNuevoCliente(cliente){
      this.customer=cliente
      this.obtenerCliente(cliente)
      this.fnGetCustomers()
    },
    customFilter(item, queryText, itemText) {
      const textOne = item.name.toLowerCase()
      const textTwo= item.surname.toLowerCase()

      const searchText = queryText.toLowerCase()

      return textOne.indexOf(searchText) > -1 ||
          textTwo.indexOf(searchText) > -1
    },
    fnSetCleanData(){
      this.customer_id=null
      this.customer=null
      this.obtenerCliente(this.customer)
    },
    handleSelectCustomer(clienteId) {
      this.customer_id=clienteId
      this.customer=this.listCustomers.find(x=>x.id==clienteId)
      this.obtenerCliente(this.customer)
    },
    limpiarListas() {
      this.listCustomers = [];
      this.listCustomersOrigin = []
    },
    handleClearCustomer(){
      this.customer=null
      this.customer_id=null
      this.obtenerCliente(this.customer)
    },
    handleViewCreateCustomer(event){
      this.$refs.formNuevoCliente.showForm('NUEVO CLIENTE','create')
    },
    fnGetCustomers() {
      ApiCustomers.get_all()
          .then(result => {
            console.log(result.data)
            this.listCustomersOrigin = result.data || []
            this.listCustomers = result.data || []
          })
          .catch(error => {
            this.limpiarListas=[]
            console.log(error)
          })
    }
  }
}
</script>

<style scoped>

</style>