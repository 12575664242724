<template>
  <v-card class="cart-container" style="height: 100% !important">
    <v-card-text class="pt-1 pl-0 pr-0" style="height: 100% !important">
      <template>

        <v-row class="pl-3 pr-3">
          <v-col cols="12" class="pt-3 pb-3">
            <DatepickerComponent :default-date="fechaVenta" :obtener-fecha="obtenerFechaVenta"></DatepickerComponent>
          </v-col>
        </v-row>
        <hr class="separator-line" v-if="selected_station && worker == null">
        <v-row class="pl-3 pr-3" v-if="selected_station && worker == null">
          <v-col cols="11" class="pb-4">
            <v-autocomplete
                :value="worker_id"
                :items="workers"
                label="Trabajador"
                hide-details
                outlined
                :item-text="customTextWorkers"
                item-value="id"
                clearable
                @change="handleSelectWorker"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="pl-3 pr-3" v-if="worker">
          <v-col cols="12" class="pt-0 pb-0">
            <v-list two-line class="pt-0 pb-0">
              <v-list-item class="pl-1 pr-1">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon color="blue" class="mr-2" size="18">
                      fas fa-user-cog
                    </v-icon>
                    <span class="text-uppercase font-weight-bold cart-label-customer">{{ worker.fullname }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span class="ml-8">{{ worker.payment_type }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon @click="handleClearWorker" color="red">
                    <v-icon>far fa-times-circle</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
        <hr class="separator-line">


        <!----INICIO CARGANDO EL COMPONENTE CLIENTES---->
        <ClienteSearchComponent :obtener-cliente="fnObtenerCliente" ref="clienteComponent"></ClienteSearchComponent>
        <!----FIN CARGANDO EL COMPONENTE CLIENTES---->

        <hr class="separator-line">
        <v-row style="height: 41% !important">
          <v-col cols="12" class="pt-1 pb-1">
            <template>
              <div class="section-list-items-cart">
                <v-list dense two-line class="list-cart-items pt-0 pb-0" style="height: 100% !important">
                  <v-list-item v-for="product in listCarrito" :key="product.id"
                               class="cart-item pl-2 pr-2"
                  >
                    <v-list-item-content class="pt-0 pb-0 cart-item-content" >
                      <v-list-item-title>
                        <span class="text-uppercase font-weight-bold" @click="handleSetearPrecio(product)">
                                                    {{ product.description }}
                                                </span>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                                                <span class="cart-item-quantity" @click="handleAddQuantity(product)">
                                                    {{ product.quantity }}
                                                </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action class="mb-1 cart-item-action">
                      <v-list-item-action-text>
                        <span v-if="product.discount_value" class="font-weight-bold mr-2">
                            {{ getAmountProductDiscount(product) | currency('S/') }}
                        </span>
                        <span v-else class="font-weight-bold mr-2">{{ product.subtotal | currency('S/') }}</span>

                        <span @click="handleIncreaseItem(product)" class="cart-item-delete mr-1">
                            <v-icon size="18" color="green">fas fa-plus</v-icon>
                        </span>
                        <span @click="handleDecreaseItem(product)" class="cart-item-delete mr-1">
                            <v-icon size="18" color="amber">fas fa-minus</v-icon>
                        </span>
                        <span @click="handleDeleteItem(product)" class="cart-item-delete">
                            <v-icon size="18" color="red">fas fa-times</v-icon>
                        </span>
                        <v-icon v-if="product.is_bonus"
                                size="18"
                                class="ml-2"
                                color="green"
                                @click="handleChangeBonus(product)">
                          fas fa-gift
                        </v-icon>
                        <v-icon v-else
                                size="18"
                                class="ml-2"
                                color="grey lighten-1"
                                @click="handleChangeBonus(product)">
                          fas fa-gift
                        </v-icon>
                      </v-list-item-action-text>
                      <v-list-item-action-text
                          v-if="product.discount_value && product.discount_value > 0">
                              <span class="label-amount-original label-price-discounted">
                                  {{ product.subtotal | currency('S/') }}
                              </span>
                      </v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </div>
            </template>
          </v-col>
        </v-row>
        <hr class="separator-line">

        <v-row>
          <v-col cols="12" class="pt-0 pb-0">
            <v-list class="pt-0">
              <v-list-item class="amount-summary">
                <v-list-item-content class="pt-1 pb-1">
                  <v-list-item-title>
                    <span class="font-weight-bold label-discount">DESCUENTO</span>
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action class="mt-1 mb-1">
                  <span class="font-weight-bold label-discount">{{ discount | currency('S/') }}</span>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>

              <v-list-item class="amount-summary">
                <v-list-item-content class="pt-1 pb-1">
                  <v-list-item-title>
                    <span class="font-weight-bold">SUBTOTAL</span>
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action class="mt-1 mb-1">
                  <span class="font-weight-bold">{{ subtotal | currency('S/') }}</span>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>

              <v-list-item class="amount-summary">
                <v-list-item-content class="pt-1 pb-1">
                  <v-list-item-title>
                    <span class="font-weight-bold">IGV</span>
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action class="mt-1 mb-1">
                  <span class="font-weight-bold">{{ igv | currency('S/') }}</span>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>

              <v-list-item class="amount-summary">
                <v-list-item-content class="pt-1 pb-1">
                  <v-list-item-title>
                    <span class="font-weight-bold">TOTAL</span>
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action class="mt-1 mb-1">
                  <span class="font-weight-bold">{{ total | currency('S/') }}</span>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>

        <v-row justify="center" v-if="!selected_station">
          <v-col cols="8" class="pt-0">
            <v-btn block dark color="green" height="45" @click="handleShowPayment">
              PAGAR {{ total | currency('S/') }}
            </v-btn>
          </v-col>
        </v-row>

        <v-row justify="center" v-else>
          <v-col cols="4" class="pt-0">
            <v-btn dark block color="blue" height="45" @click="handleSaveSale" :loading="processing">
              GUARDAR
            </v-btn>
          </v-col>
          <v-col cols="7" class="pt-0" v-if="sale_station">
            <v-btn dark block color="green" height="45" @click="handleShowPayment">
              PAGAR {{ total | currency('S/') }}
            </v-btn>
          </v-col>
        </v-row>

      </template>
    </v-card-text>

    <FormularioPago ref="formularioPago"
                    :customer="cliente"
                    :sales="formularioPago"
                    :fn-registro-success="limpiarInformacion"
                    :cart="listCarrito"/>

    <v-snackbar v-model="cant_pay" top color="error">
      {{ message_validation }}
      <v-btn dark text @click="handleCloseMessageValidation">
        Cerrar
      </v-btn>
    </v-snackbar>
  </v-card>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'

import ArrayTools from '@/helpers/ArrayTools';
import Payment from '@/components/sales/Payment';
import Prices from '@/components/sales/Prices';
import CreateUpdate from '@/components/customers/CreateUpdate';
import DatepickerComponent from "../../../components/DatepickerComponent";
import ClienteSearchComponent from "../../../components/ClienteSearchComponent";
import FormularioPago from "./FormularioPago";
import NumberTools from "../../../../helpers/NumberTools";

export default {
  name: 'Carrito',
  props: [
    'listCarrito',
    'fnRegistroSuccessPrincipal',
    'handleDecreaseItem',
    'handleIncreaseItem',
    'handleDeleteItem',
    'handleChangeBonus',
    'handleAddQuantity',
    'handleSetearPrecio'
  ],
  components: {
    FormularioPago,
    ClienteSearchComponent,
    DatepickerComponent,

    // Payment,
    // Prices,
    // CreateUpdate
  },
  data() {
    return {
      cliente: null,
      menuDateInvoice: false,
      cant_pay: false,
      message_validation: null,
      processing: false,
      fechaVenta: null,
      customer: null,
      subtotal: 0,
      igv: 0.0,
      total: 0.0,
      product: null,
      discount: 0.0,
      type_operation: 'sale',
      date_invoice: '',
      sale_station: '',
      worker: '',
      worker_id: '',
      rounding: '',
      total_rounded: 0.0,
      total_gratuitas: 0.0,
      formularioPago_show: false,
      formularioPago: {
        'subtotal': 0.0,
        'igv': 0.0,
        'total': 0.0,
        'sale': null,
        'discount': 0.0,
        'type_operation': '',
        'date_invoice': '',
        'rounding': 0,
        'total_rounded': 0,
        'total_gratuitas': 0,
        'sale_station': '',
        'worker_id': ''
      }

    }
  },
  computed: {
    ...mapState('authentication', [
      'office_id',
    ]),

    ...mapState('stations', [
      'selected_station',

    ])
  },
  methods: {

    limpiarInformacion() {
      console.log('limpiando carrito')
      this.$refs.formularioPago.show('hide')
      this.cliente = null
      this.menuDateInvoice = false
      this.cant_pay = false
      this.message_validation = null
      this.processing = false
      this.customer = null
      this.subtotal = 0
      this.igv = 0.0
      this.total = 0.0
      this.product = null
      this.discount = 0.0
      this.type_operation = 'sale'
      this.date_invoice = ''
      this.sale_station = ''
      this.worker = ''
      this.worker_id = ''
      this.rounding = ''
      this.total_rounded = 0.0
      this.total_gratuitas = 0.0
      this.formularioPago_show = false
      this.formularioPago = {
        'subtotal': 0.0,
        'igv': 0.0,
        'total': 0.0,
        'sale': null,
        'discount': 0.0,
        'type_operation': '',
        'date_invoice': '',
        'rounding': 0,
        'total_rounded': 0,
        'total_gratuitas': 0,
        'sale_station': '',
        'worker_id': ''
      }
      this.$refs.clienteComponent.fnSetCleanData()
      this.fnRegistroSuccessPrincipal()
    },
    setFormularioPago() {
      let sale = {
        office_id: this.office_id,
        // id: this.sale_station,
        date_invoice: this.fechaVenta,
        // worker_id: this.worker_id,
        customer_id: this.cliente.id || undefined,
        type_operation: this.type_operation,
        products: this.listCarrito,
        discount: this.discount,
        subtotal: this.subtotal,
        igv: this.igv,
        total: this.total,
        rounding: this.rounding,
        total_rounded: this.total_rounded,
        total_gratuitas: this.total_gratuitas,
        // station_id: this.selected_station.id
      }

      return sale;
    },

    fnObtenerCliente(event) {

      this.cliente = event
    },

    obtenerFechaVenta(evento) {
      this.fechaVenta = evento
    },

    customText(item) {
      let text = item.fullname

      return text
    },
    customTextWorkers(item) {
      let text = item.fullname

      return text
    },
    handleSelectCustomer(value) {
      if (value == null || value == undefined) {
        //this.customer_id = null;
        this.select_customer(null);
      } else {
        let selected_customer = ArrayTools.getElementById(this.customers, value);
        this.select_customer(selected_customer);
      }
    },
    handleSelectWorker(value) {
      if (value == null || value == undefined) {
        //this.customer_id = null;
        this.selectWorker(null);
      } else {
        let selected_worker = ArrayTools.getElementById(this.workers, value);
        this.selectWorker(selected_worker);
      }
    },
    handleClearCustomer() {
      //this.customer_id = null
      this.select_customer(null);
    },
    handleClearWorker() {
      this.selectWorker(null);
    },
    handleCloseMessageValidation() {
      this.cant_pay = false;
      this.message_validation = null;
    },
    handleShowPayment() {
      if (this.cliente == null) {
        this.cant_pay = true;
        this.message_validation = 'Debe seleccionar un cliente.';
      } else if (this.listCarrito.length === 0) {
        this.cant_pay = true;
        this.message_validation = 'Debe seleccionar al menos un producto.';
      } else {

        if (this.type_operation === 'sale') {
          // this.formularioPago_show = true
          this.$refs.formularioPago.show('show')
          let setForm = this.setFormularioPago();
          console.log('formulario ', setForm)
          this.formularioPago = {...this.formularioPago, ...setForm}
          // this.$refs.payment.showForm('Registro de pago de venta');
        }
        // else {
        //   // this.$refs.payment.showForm('Registro de pago de pedido');
        // }
      }
    },
    hanldleClickItemCart(product) {
      console.log('setear precio de product', product)
      // this.select_product(product);
      // this.setViewPrices(true);
    },
    handleChangeTypeOperation(value) {
      this.setTypeOperation(value);
      this.clearData();
    },
    getAmountProductDiscount(product) {
      let amount = 0;
      let subtotal = Math.round(product.subtotal * 100) / 100;
      let discount = Math.round(product.discount_value * 100) / 100;
      amount = Math.round((subtotal - discount) * 100) / 100;
      return amount;
    },
    handleViewCreateCustomer() {
      this.$refs.createUpdate.showForm('Nuevo cliente', 'create');
    },
    async handleSaveSale() {
      this.processing = true;
      if (this.customer == null) {
        this.cant_pay = true;
        this.message_validation = 'Debe seleccionar un cliente.';
        this.processing = false;
      } else if (this.listCarrito.length == 0) {
        this.cant_pay = true;
        this.message_validation = 'Debe seleccionar al menos un producto.';
        this.processing = false;
      } else {
        // let sale = this.setEntitySale();
        // if (this.sale_station == null) {
        //   await this.storeByStation(sale);
        // } else {
        //   await this.updateByStation(sale);
        // }
        this.processing = false;
      }
    },
    customFilter(item, queryText, itemText) {
      let textName = '';
      if (item.name) {
        textName = item.fullname.toLowerCase();
      }
      let textDocument = '';
      if (item.document) {
        textDocument = item.document.toLowerCase();
      }
      let searchText = queryText.toLowerCase()


      return textName.indexOf(searchText) > -1 || textDocument.indexOf(searchText) > -1
    },
    calculateDiscountValue(product) {
      let discount_value = 0;
      if (product.discount_type) {
        discount_value = parseFloat(product.discount_amount);
        if (product.discount_type == 'Porcentaje') {
          let percentage = parseFloat(product.discount_amount);
          let discount = parseFloat(product.subtotal) * (percentage / 100);
          discount_value = Math.round(discount * 100) / 100;
        }
      }

      return discount_value;
    },
  },
  created() {

  },
  mounted() {

    let filters = {
      office_id: this.office_id
    }
    // this.getWorkers(filters);
    if (!this.selected_station) {
      // this.run_set_cart([]);
      // this.clearData();
      // this.run_set_sale(null);
    }
  },
  watch: {
    listCarrito() {
      console.log('escuchando cambios en lista carrito',this.listCarrito)
      let sum_discount = 0;
      let sum_subtotal = 0;
      let sum_igv = 0;
      let sum_total = 0;
      let sum_total_gratuitas = 0;
      this.subtotal = 0
      this.igv = 0.0
      this.total = 0.0
      this.discount = 0.0
      this.rounding = 0
      this.listCarrito.forEach(product => {
        product.subtotal=parseFloat(product.quantity)*parseFloat(product.price)
        if (product.is_bonus === true) {
          sum_total_gratuitas = sum_total_gratuitas + parseFloat(product.subtotal);
        } else {
          sum_subtotal = sum_subtotal + parseFloat(product.subtotal) - NumberTools.getNumberDecimalValue(product.discount_value);
          sum_discount = sum_discount + NumberTools.getNumberDecimalValue(product.discount_value);
        }
      })
      sum_total = sum_subtotal;
      let amount_subtotal = Math.round((sum_subtotal / 1.18) * 100) / 100;
      sum_igv = Math.round((sum_total - amount_subtotal) * 100) / 100;

      //calculo de redondeo a 1 decimal
      let amount_total_rounded = Math.round(sum_total * 10) / 10;
      let amoutn_rounded = Math.round(amount_total_rounded * 100) / 100;
      let amount_rounding = Math.round((amoutn_rounded - sum_total) * 100) / 100;

      let amount_gratuitas = Math.round(sum_total_gratuitas * 100) / 100;
      console.log('SUMA TOTAL',sum_total)
      this.discount = sum_discount
      this.subtotal = amount_subtotal
      this.igv = sum_igv
      this.total = sum_total
      this.rounding = amount_rounding
      this.total_rounded = amoutn_rounded
      this.total_gratuitas = amount_gratuitas
    }
  }
}
</script>

<style scoped>
.label-discount {
  color: #F44336;
}

.amount-summary {
  min-height: 30px !important;
}

.list-cart-items {
  overflow-y: auto;
}

.label-amount-original {
  display: block !important;
}

.v-list-item__action-text {
  font-size: .85rem !important;
}

.label-price-discounted {
  text-decoration: line-through;
  text-decoration-color: #C62828;
}

.section-list-items-cart {
  height: 35vh;
  overflow-y: auto;
}

.cart-item-content {
  cursor: pointer;
}
</style>