<template>
  <v-navigation-drawer v-model="drawer" width="400" app temporary right>
    <div class="navigation-head">
      <span>{{ titleForm }}</span>
    </div>

    <div class="pl-3 pt-3 pr-3" style="height: 80vh;">
      <v-container class="pt-1">
        <v-row>
          <v-col cols="12">
            <v-autocomplete
                ref="type_document_id"
                v-model="customerForm.type_document_id"
                :items="types_documents"
                label="Tipo de documento"
                outlined
                :item-text="customText"
                item-value="id"
                clearable
                @change="handleSelectTypeDocument"
                :rules="[() => !!customerForm.type_document_id || 'Es necesario seleccionar un tipo de documento']"
                hide-details="auto"
                required
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="11">
            <v-text-field
                ref="document"
                v-model="customerForm.document"
                label="Documento"
                outlined
                :loading="searching"
                :rules="[() => !!customerForm.document || 'Es necesario ingresar número de documento']"
                hide-details="auto"
                required
            ></v-text-field>
          </v-col>
          <v-col cols="1" class="pl-0">
            <v-icon class="mt-2" @click="handleSearchDocument">fas fa-search</v-icon>
          </v-col>
        </v-row>
        <v-row v-if="!is_company">
          <v-col cols="12">
            <v-text-field
                ref="name"
                v-model="customerForm.name"
                label="Nombre"
                outlined
                :rules="[() => !!customerForm.name || 'Es necesario ingresar el nombre']"
                hide-details="auto"
                required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="!is_company">
          <v-col cols="12">
            <v-text-field
                ref="surname"
                v-model="customerForm.surname"
                label="Apellidos"
                outlined
                :rules="[() => !!customerForm.surname || 'Es necesario ingresar apellidos']"
                hide-details="auto"
                required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="is_company">
          <v-col cols="12">
            <v-text-field
                ref="name"
                v-model="customerForm.name"
                label="Razón Social"
                outlined
                :rules="[() => !!customerForm.name || 'Es necesario ingresar razón social']"
                hide-details="auto"
                required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="customerForm.email" label="Email" outlined hide-details></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="customerForm.phone" label="Teléfono" outlined hide-details></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea
                v-model="customerForm.address"
                outlined
                label="Dirección"
                rows="2"
                hide-details
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
                v-model="customerForm.credit_amount"
                label="Crédito"
                outlined
                hide-details
                prefix="S/"
                type="number"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-btn block height="40" dark color="red" @click="handleCancel">
              <v-icon class="mr-1" size="16">far fa-times-circle</v-icon>Cancelar
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
                dark
                color="success"
                block
                height="40"
                :disabled="process_operation"
                @click="handleStore"
            >
              <v-icon class="mr-1" size="16">far fa-save</v-icon>Guardar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions } from "vuex";

import ArrayTools from "../../helpers/ArrayTools";
import CustomersApi from "../../apis/Customers";

export default {
  name: "nuevoCliente",
  props:{getCliente:Function},
  data() {
    return {
      drawer: false,
      titleForm: null,
      action: null,
      is_company: false,
      searching: false,
      customerForm: {
        id: null,
        type_document_id: null,
        document: null,
        name: null,
        surname: null,
        email: null,
        phone: null,
        address: null,
        credit_amount: null,
      },
      process_operation: false,
    };
  },
  computed: {
    ...mapState("documents", ["types_documents"]),
    ...mapState("customers", ["person"]),
  },
  methods: {
    ...mapActions("documents", ["getTypesDocuments"]),
    ...mapActions("customers", [
      "store",
      "update",
      "emptyPerson",
      "searchDocument",
    ]),
    ...mapActions('notifications', [
      'store_notification'
    ]),
    async showForm(title, action, customer = null) {
      await this.clearData();
      this.titleForm = title;
      this.action = action;

      this.drawer = !this.drawer;
    },
    clearData() {
      this.searching = false;
      this.is_company = false;
      this.customerForm.id = null;
      this.customerForm.type_document_id = null;
      this.customerForm.document = null;
      this.customerForm.name = null;
      this.customerForm.surname = null;
      this.customerForm.email = null;
      this.customerForm.phone = null;
      this.customerForm.address = null;
      this.customerForm.credit_amount = null;
      this.clearErrors();
    },
    setCustomer(customer) {
      this.customerForm.id = customer.id;
      this.customerForm.type_document_id = customer.type_document_id;
      this.customerForm.document = customer.document;
      this.customerForm.name = customer.name;
      this.customerForm.surname = customer.surname;
      this.customerForm.email = customer.email;
      this.customerForm.phone = customer.phone;
      this.customerForm.address = customer.address;
      this.customerForm.credit_amount = customer.credit_amount;

      let typeDocument = ArrayTools.getTypeDocumentByID(
          this.types_documents,
          customer.type_document_id
      );
      if (typeDocument.slug === "ruc") {
        this.is_company = true;
      } else {
        this.is_company = false;
      }
    },
    closeForm() {
      this.titleForm = null;
      this.action = null;
      this.clearData();
      this.drawer = false;
    },
    handleCancel() {
      this.closeForm();
    },
    customText(item) {
      let text = item.name;

      return text;
    },
    handleSelectTypeDocument(value)
    {
      if (value === undefined || value === null) {
        this.is_company = false;
      } else {
        let typeDocument = ArrayTools.getTypeDocumentByID(
            this.types_documents,
            value
        );
        if (typeDocument.slug === "ruc") {
          this.is_company = true;
        } else {
          this.is_company = false;
        }
      }
    },
    async handleStore() {
      this.process_operation = true;
      if (!this.existErrors()) {
        let validateDocument = this.validateDocumentNumber(this.customerForm.type_document_id, this.customerForm.document);
        if (validateDocument == 'success') {
          await this.fnRegistrarCliente(this.customerForm);
          this.process_operation = false;
          this.closeForm();
        } else {
          this.process_operation = false;
          this.store_notification({
            type: 'error',
            message: validateDocument
          });
        }
      } else {
        this.process_operation = false;
      }
    },
    existErrors() {
      let hasErrors = false;

      Object.keys(this.customerForm).forEach((f) => {
        if (this.$refs[f]) {
          if (!this.customerForm[f]) hasErrors = true;
          this.$refs[f].validate(true);
        }
      });

      return hasErrors;
    },
    clearErrors() {
      Object.keys(this.customerForm).forEach((f) => {
        if (this.$refs[f]) this.$refs[f].reset();
      });
    },
    async handleSearchDocument() {
      this.searching = true;
      if (this.customerForm.document) {
        let document = this.customerForm.document;
        await this.searchDocument(document);
        this.searching = false;
        if (this.person) {
          let personFounded = Object.assign({}, this.person);
          this.emptyPerson();
          this.customerForm.document = personFounded.document;
          this.customerForm.name = personFounded.name;
          this.customerForm.surname = personFounded.surname;
          this.customerForm.address = personFounded.address;
        }
      } else {
        this.searching = false;
      }
    },
    validateDocumentNumber (type, document) {
      let message = 'success';
      if (type == 2) {
        //ruc
        if (document.length != 11) {
          message = 'RUC debe tener 11 dígitos';
        }
      }

      return message;
    },
    async fnRegistrarCliente(cliente){
        return await CustomersApi.store(cliente)
            .then( response => {
              const data=response.data
              this.getCliente(data)
            })
            .catch( error => {
              console.log(error)
            })
    }
  },
  mounted() {
    this.getTypesDocuments();
  },
};
</script>

<style scoped>
</style>